import React from 'react';
import { string } from 'prop-types';
import loadable from '@loadable/component';

const LazyLandingPrices = loadable(() => import('components/landing-prices'));

const LandingPricesSection = ({ hash = '' }) => (
        <LazyLandingPrices />
);

LandingPricesSection.propTypes = { hash: string };

export default LandingPricesSection;
